import { css } from '@emotion/react'
import { CONTACT_GENERAL_EMAIL } from 'constants/contact'
import { LINKS } from 'constants/links'
import { Flex } from 'driverama-core/components/Flex'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import Logo from 'driverama-core/images/Logo.svg'
import { media } from 'driverama-core/styles/media'
import { size, spaceX } from 'driverama-core/styles/spacing'
import { color } from 'driverama-core/styles/variables'
import { useSession } from 'driverama-core/utils/auth'
import { isRegion } from 'driverama-core/utils/env'
import { ForwardedRef, forwardRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { FooterLink } from 'sections/footer/FooterLink'
import { MobileMenu } from 'sections/footer/MobileMenu'
import { Social } from 'sections/social/Social'
import { logCustomFbEvent } from 'utils/analytics/facebook'
import { logGtmPhoneClick } from 'utils/gtm/gtmEvents'
import { useContactPhone } from 'utils/useContactPhone'
import { SWrapper } from './Footer.styled'
import { FooterContactLink } from './FooterContactLink'
import {
  FooterContactLinkIcon,
  FooterContactLinkIconName
} from './FooterContactLinkIcon'
import { FooterText } from './FooterText'

interface Props {
  headerMenuVisible?: boolean
  isHomepage?: boolean
}

function FooterWithoutRef(props: Props, ref: ForwardedRef<HTMLElement>) {
  const { t } = useTranslation(['core'])
  const { session } = useSession()
  const { contactPhone, contactPhoneAsLink } = useContactPhone()

  const isSellingShutDown = driverama.flags.IS_SELLING_SHUT_DOWN

  const onPhoneClick = () => {
    logGtmPhoneClick(contactPhone)
    logCustomFbEvent('phone_number')
  }

  const renderedAddress = (
    <>
      {isRegion('de') && (
        <div>
          <FooterText>{t('core:address_de_title')}</FooterText>

          <FooterText>{t('core:address_de_line_1_text')}</FooterText>

          <FooterText>{t('core:address_de_line_2_text')}</FooterText>

          <FooterText>{t('core:address_de_line_3_text')}</FooterText>
        </div>
      )}

      {isRegion('nl') && (
        <div>
          <FooterText>{t('core:address_nl_title')}</FooterText>

          <FooterText>{t('core:address_nl_line_1_text')}</FooterText>

          <FooterText>{t('core:address_nl_line_2_text')}</FooterText>

          <FooterText>{t('core:address_nl_line_3_text')}</FooterText>
        </div>
      )}
    </>
  )

  const renderedFooterLinks = (
    <>
      <Spacer size={[7, 7, 11]} axis="vertical" />
      <Flex variant="column">
        <FooterLink href={LINKS.cookies}>
          {t('core:footer_link_cookies')}
        </FooterLink>

        <FooterLink href={LINKS.privacyPolicy}>
          {t('core:privacy_policy')}
        </FooterLink>

        <FooterLink href={LINKS.rightToWithdrawal}>
          {t('core:footer_link_right_to_withdrawal')}
        </FooterLink>

        <FooterLink href={LINKS.impressum}>
          {t('core:footer_menu_link_impressum')}
        </FooterLink>

        {driverama.flags.IS_SELLING_ENABLED && (
          <>
            <FooterLink href={LINKS.termsAndConditions}>
              {t('core:footer_menu_link_terms_and_conditions')}
            </FooterLink>

            <FooterLink href={LINKS.complaintsProcedure}>
              {t('core:footer_menu_link_complaints_procedure')}
            </FooterLink>

            {!isSellingShutDown && (
              <FooterLink href={LINKS.referralBonus}>
                {t('core:footer_menu_link_referral_bonus')}
              </FooterLink>
            )}
          </>
        )}
      </Flex>
    </>
  )

  return (
    <SWrapper ref={ref}>
      {props.headerMenuVisible !== false && (
        <MobileMenu isHomepage={props.isHomepage} />
      )}

      <div
        css={css`
          grid-column-start: col-1;
          grid-column-end: col-3;
          grid-row-start: 2;

          @media ${media.lte('tablet')} {
            grid-column-start: col-1;
            grid-column-end: col-1;
            grid-row-start: 1;
          }
        `}
      >
        <Logo
          css={css`
            display: block;
            color: ${color('white')};
          `}
        />
      </div>

      <div
        css={css`
          grid-column-start: col-1;
          grid-column-end: col-3;
          grid-row-start: 3;

          @media ${media.lte('tablet')} {
            grid-row-start: 5;
            grid-column-end: col-4;
          }
        `}
      >
        <FooterText
          css={css`
            margin-bottom: ${size(6)};

            @media ${media.gt('tablet')} {
              display: none;
            }

            & a,
            a:any-link {
              text-decoration: underline;
            }
          `}
        >
          <Trans t={t} i18nKey="core:part_of_aures">
            <a
              target="_blank"
              href="https://www.auresholdings.eu/"
              rel="noreferrer"
            />
          </Trans>
        </FooterText>

        <FooterText size="small">
          {t('core:copyright', { year: new Date().getFullYear() })}.
        </FooterText>
      </div>

      <Flex
        variant="column"
        wrap="wrap"
        css={css`
          grid-column: col-4 / col-6;
          grid-row-start: 2;
          margin-top: -${size(1)};

          @media ${media.lte('tablet')} {
            grid-column: col-1 / col-4;
            display: block;
            column-count: 4;
          }

          @media ${media.lte('MD')} {
            column-count: 2;
          }

          @media ${media.lte('XXS')} {
            column-count: 1;
          }
        `}
      >
        {/* <FooterLink href={LINKS.searchCars}>
          {t('core:footer_menu_link_search_cars')}
        </FooterLink> */}
        {/* <FooterLink href={LINKS.blog}>
          {t('core:footer_menu_link_blog')}
        </FooterLink> */}
        {/* <FooterLink href={LINKS.home}>
          {t('core:footer_menu_link_sell_cars')}
        </FooterLink> */}
        {driverama.flags.IS_SELLING_ENABLED && !isSellingShutDown && (
          <>
            <FooterLink href={LINKS.financing}>
              {t('core:footer_menu_link_finance')}
            </FooterLink>

            <FooterLink href={LINKS.extendedWarranties}>
              {t('core:footer_menu_link_warranty')}
            </FooterLink>
          </>
        )}
        <FooterLink href={LINKS.aboutUs}>
          {t('core:footer_menu_link_about_us')}
        </FooterLink>
        <FooterLink href={LINKS.blog}>
          {t('core:footer_menu_link_blog')}
        </FooterLink>
        {session?.access_token ? (
          <FooterLink href={LINKS.profile}>
            {t('core:footer_menu_link_profile')}
          </FooterLink>
        ) : (
          <FooterLink href={LINKS.sign}>
            {t('core:footer_menu_link_log_in')}
          </FooterLink>
        )}
        <FooterLink href={LINKS.contactUs}>
          {t('core:footer_menu_link_contact')}
        </FooterLink>
        <FooterLink href={LINKS.press}>
          {t('core:footer_menu_link_press')}
        </FooterLink>
        {!isRegion('nl') && (
          <FooterLink href={LINKS.branches}>
            {t('core:footer_menu_link_branches')}
          </FooterLink>
        )}
        <FooterLink href={LINKS.careers}>
          {t('core:footer_menu_link_careers')}
        </FooterLink>

        {driverama.flags.IS_SELLING_ENABLED && !isSellingShutDown && (
          <>
            <FooterLink href={LINKS.delivery}>
              {t('core:footer_menu_link_delivery')}
            </FooterLink>

            <FooterLink href={LINKS.carRegistration}>
              {t('core:footer_menu_link_car_registration')}
            </FooterLink>
          </>
        )}

        {driverama.flags.IS_STANDARD_LP_ENABLED && (
          <FooterLink href={LINKS.driveramaStandard}>
            {t('core:footer_menu_link_driverama_standard')}
          </FooterLink>
        )}

        {driverama.flags.IS_B2B_ENABLED && (
          <FooterLink href={LINKS.b2bSell}>
            {t('core:footer_menu_link_b2b')}
          </FooterLink>
        )}
      </Flex>

      <div
        css={css`
          grid-column-start: col-7;
          grid-column-end: col-9;
          grid-row-start: 2;

          display: flex;
          justify-content: space-between;
          flex-direction: column;

          @media ${media.lte('tablet')} {
            display: none;
          }
        `}
      >
        {renderedAddress}
        {renderedFooterLinks}
      </div>

      <div
        css={css`
          display: flex;
          flex-direction: column;
          grid-column-start: col-10;
          grid-column-end: col-12;
          grid-row-start: 2;

          @media ${media.lte('tablet')} {
            grid-column-start: col-1;
            grid-column-end: col-4;
            grid-row-start: 3;
          }
        `}
      >
        <FooterContactLink
          iconName={FooterContactLinkIconName.Phone}
          href={contactPhoneAsLink}
          title={contactPhone}
          onClick={onPhoneClick}
        />
        <Spacer size={4} axis="vertical" />
        <FooterContactLink
          iconName={FooterContactLinkIconName.Mail}
          href={`mailto:${CONTACT_GENERAL_EMAIL}`}
          title={`${CONTACT_GENERAL_EMAIL}`}
        />
        <Spacer size={4} axis="vertical" />
        <div
          css={css`
            width: fit-content;
            display: flex;

            @media ${media.gt('tablet')} {
              display: none;
            }
          `}
        >
          <FooterContactLinkIcon
            iconName={FooterContactLinkIconName.Address}
            css={css({ color: color('white') })}
          />
          <Spacer size={3} axis="horizontal" />
          <FooterText as="div">{renderedAddress}</FooterText>
        </div>

        <Spacer size={2} />

        <div
          css={css`
            @media ${media.gt('tablet')} {
              display: none;
            }
            @media ${media.lte('tablet')} {
              margin-bottom: ${size(-13)};
            }
          `}
        >
          {renderedFooterLinks}
        </div>
        <FooterText
          css={css`
            margin-top: auto;
            margin-bottom: ${size(2)};

            @media ${media.lte('tablet')} {
              display: none;
            }

            & a,
            a:any-link {
              text-decoration: underline;
            }
          `}
        >
          <Trans t={t} i18nKey="core:part_of_aures">
            <a
              target="_blank"
              href="https://www.auresholdings.eu/"
              rel="noreferrer"
            />
          </Trans>
        </FooterText>
      </div>

      <div
        css={css`
          grid-column-start: col-10;
          grid-column-end: col-12;
          grid-row-start: 3;

          @media ${media.lte('tablet')} {
            grid-column-start: col-2;
            grid-column-end: col-4;
            grid-row-start: 1;
            justify-self: end;
          }
        `}
      >
        <Social
          css={css`
            color: ${color('white')};
            ${spaceX(5)};

            @media ${media.lte('XXS')} {
              position: relative;
              top: ${size(10)};
            }
          `}
        />
      </div>
    </SWrapper>
  )
}

export const Footer = forwardRef(FooterWithoutRef)
