import { css } from '@emotion/react'
import Link from 'next/link'
import { PropsWithChildren } from 'react'
import { color } from 'driverama-core/styles/variables'
import { FooterText } from './FooterText'
import { size } from 'driverama-core/styles/spacing'

type Props = {
  href: string
}

export function FooterLink(props: PropsWithChildren<Props>) {
  const { href, children } = props

  return (
    <Link href={href} passHref>
      <FooterText
        as="a"
        css={css`
          width: fit-content;
          padding: ${size(1)} 0;
          break-inside: avoid;

          &:any-link {
            color: ${color('almond-l-200')};
          }

          @media (hover: hover) {
            &:hover {
              text-decoration: underline;
            }
          }
        `}
      >
        {children}
      </FooterText>
    </Link>
  )
}
