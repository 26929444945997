import { css } from '@emotion/react'
import { EXTERNAL_LINKS } from 'constants/links'
import IconFacebook from 'images/icons/social/IconFacebook.svg'
import IconInstagram from 'images/icons/social/IconInstagram.svg'
import IconLinkedin from 'images/icons/social/IconLinkedin.svg'
import IconTwitter from 'images/icons/social/IconTwitter.svg'
import { spaceX } from 'driverama-core/styles/spacing'
import { getRegion } from 'driverama-core/utils/env'

type Props = {
  className?: string
}

export function Social(props: Props) {
  const links = EXTERNAL_LINKS(getRegion())

  return (
    <div
      className={props.className}
      css={css`
        display: flex;
        align-items: center;
        ${spaceX(3)}
      `}
    >
      {links.twitter && (
        <a href={links.twitter} target="_blank" rel="noreferrer noopener">
          <IconTwitter />
        </a>
      )}

      {links.facebook && (
        <a href={links.facebook} target="_blank" rel="noreferrer noopener">
          <IconFacebook />
        </a>
      )}

      {links.linkedIn && (
        <a href={links.linkedIn} target="_blank" rel="noreferrer noopener">
          <IconLinkedin />
        </a>
      )}

      {links.instagram && (
        <a href={links.instagram} target="_blank" rel="noreferrer noopener">
          <IconInstagram />
        </a>
      )}
    </div>
  )
}
