import { ComponentProps, forwardRef } from 'react'
import { css } from '@emotion/react'
import { TextBody } from 'driverama-core/components/text/Text'
import { color } from 'driverama-core/styles/variables'

type Props = ComponentProps<typeof TextBody>

export const FooterText = forwardRef<HTMLElement, Props>((props, ref) => {
  const { children, as, ...rest } = props

  return (
    <TextBody
      as={as ?? 'p'}
      ref={ref}
      css={css`
        display: block;
        color: ${color('almond-l-200')};
      `}
      {...rest}
    >
      {children}
    </TextBody>
  )
})
