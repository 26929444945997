import { assertUnreachable } from 'driverama-core/utils/types'
import IconMail from 'images/icons/IconMail.svg'
import IconPhone from 'images/icons/IconPhone.svg'
import IconMapPin from 'images/icons/IconMapPin.svg'

export enum FooterContactLinkIconName {
  Phone,
  Mail,
  Address
}

type Props = {
  iconName: FooterContactLinkIconName
} & React.SVGProps<SVGSVGElement>

export function FooterContactLinkIcon(props: Props) {
  const { iconName, ...rest } = props

  switch (iconName) {
    case FooterContactLinkIconName.Mail:
      return <IconMail {...rest} />
    case FooterContactLinkIconName.Phone:
      return <IconPhone {...rest} />
    case FooterContactLinkIconName.Address:
      return <IconMapPin {...rest} />
    default:
      return assertUnreachable(iconName)
  }
}
