import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { media } from 'driverama-core/styles/media'
import { size, spaceX } from 'driverama-core/styles/spacing'
import { color, shadow, time, zIndex } from 'driverama-core/styles/variables'
import { motion } from 'framer-motion'

export const SAnimatedDiv = styled(motion.div)<{ variant: 'dark' | 'light' }>`
  display: none !important;

  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: ${zIndex('sticky')};

  padding-bottom: env(safe-area-inset-bottom);

  box-shadow: ${shadow(3)};
  transition: background-color ${time('control')} ease-in-out;

  @media ${media.lte('tablet')} {
    display: block !important;
  }

  @media ${media.lte('tablet')} and (orientation: landscape) {
    display: none !important;
  }

  ${({ variant }) => {
    if (variant === 'dark') {
      return css`
        --header-bg-color: #11223a;
        --border-top-color: ${color('white', 0.15)};
      `
    }

    return css`
      --header-bg-color: ${color('white')};
      --border-top-color: ${color('night-l-800')};
    `
  }}

  background-color: var(--header-bg-color);
`

export const Wrapper = styled.div<{ height?: number }>`
  justify-content: space-around;
  align-items: center;

  ${spaceX(8)};

  @media ${media.lte('tablet')} {
    display: flex;

    width: 100%;

    height: ${({ height }) => height}px;

    ${spaceX(0)};

    padding-left: ${size(40)};
    padding-right: ${size(40)};

    transition: all ${time('control')};

    z-index: ${zIndex('notification')};
    border-top: 2px solid var(--border-top-color);
  }

  @media ${media.lte('MD')} {
    max-width: ${size(125)};
    margin: 0 auto;
    padding-left: ${size(10)};
    padding-right: ${size(10)};
  }

  @media ${media.lte('SM')} {
    padding-left: ${size(9)};
    padding-right: ${size(9)};
  }

  @media ${media.lte('XS')} {
    padding-left: ${size(8)};
    padding-right: ${size(8)};
  }
`
