import { LINKS } from 'constants/links'
import IconFinance from 'images/icons/menu/IconFinance.svg'
import IconGarage from 'images/icons/menu/IconGarage.svg'
import IconSearch from 'images/icons/menu/IconSearch.svg'
import IconSell from 'images/icons/menu/IconSell.svg'
import IconTradeIn from 'images/icons/menu/IconTrade.svg'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useWindowScroll } from 'react-use'
import { HeaderItem } from 'sections/header/HeaderItem'
import { SAnimatedDiv, Wrapper } from './MobileMenu.styled'

export const mobileMenuHeight = 56

type Props = { isHomepage?: boolean }

export function MobileMenu({ isHomepage }: Props) {
  const { t } = useTranslation(['core'])

  const prevScrollY = useRef(0)
  const [isScrollingDown, setIsScrollingDown] = useState(false)
  const { y } = useWindowScroll()

  const isSellingShutDown = driverama.flags.IS_SELLING_SHUT_DOWN
  const isSearchCarsShutDown = driverama.flags.IS_SEARCH_CARS_SHUT_DOWN

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY

      if (currentScrollY < prevScrollY.current && isScrollingDown) {
        setIsScrollingDown(false)
      } else if (currentScrollY > prevScrollY.current && !isScrollingDown) {
        setIsScrollingDown(true)
      }

      // when flicking to the top, the browser rubber bands
      // which must not be interpreted as scrolling down
      prevScrollY.current = Math.max(0, currentScrollY)
    }

    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => window.removeEventListener('scroll', handleScroll)
  }, [isScrollingDown])

  const headerItemVariant = isHomepage && y <= 0 ? 'light' : 'dark'

  return (
    <SAnimatedDiv
      variant={isHomepage && y <= 0 ? 'dark' : 'light'}
      initial={{ bottom: 0 }}
      animate={{ bottom: isScrollingDown ? -mobileMenuHeight * 2 : 0 }}
      transition={{ duration: 0.3, ease: 'easeInOut' }}
    >
      <Wrapper height={mobileMenuHeight}>
        <HeaderItem
          variant={headerItemVariant}
          icon={<IconSell />}
          label={t('core:header_menu_link_sell_cars')}
          href={
            driverama.flags.IS_SELLING_ENABLED && !isSellingShutDown
              ? LINKS.homeSelling
              : LINKS.home
          }
          buttonName="sell"
        />

        {driverama.flags.IS_SELLING_ENABLED && !isSearchCarsShutDown && (
          <HeaderItem
            variant={headerItemVariant}
            icon={<IconSearch />}
            label={t('core:header_menu_link_search_cars')}
            href={LINKS.carsSearch}
            buttonName="search_cars"
          />
        )}

        {!isSellingShutDown && (
          <HeaderItem
            variant={headerItemVariant}
            icon={<IconTradeIn />}
            label={t('core:header_menu_link_trade_in')}
            href={LINKS.tradeIn}
            buttonName="trade_in"
          />
        )}

        {driverama.flags.IS_B2B_ENABLED && (
          <HeaderItem
            variant={headerItemVariant}
            icon={<IconGarage />}
            label={t('core:header_menu_link_b2b')}
            href={LINKS.b2bSell}
            buttonName="car_dealers"
          />
        )}

        {driverama.flags.IS_SELLING_ENABLED && !isSellingShutDown && (
          <HeaderItem
            variant={headerItemVariant}
            icon={<IconFinance />}
            label={t('core:header_menu_link_finance')}
            href={LINKS.financing}
            buttonName="finance"
          />
        )}
      </Wrapper>
    </SAnimatedDiv>
  )
}
