import {
  AnalyticsEvent,
  ExtractEventParameters,
  GetEventNames
} from 'driverama-core/analytics/types'
import { hasWindow } from 'driverama-core/utils/dom'

declare global {
  interface Window {
    fbq: (...arg: unknown[]) => void // events for FB pixel, may not be avaible on local
  }
}

type FbEvents =
  | AnalyticsEvent<'Contact'>
  | AnalyticsEvent<'SubmitApplication'>
  // Uzivatel pouzije fulltext vyhledavani
  | AnalyticsEvent<
      'Search',
      { content_type: 'vehicle'; content_ids: string[]; currency: string }
    >
  // Kosik - Car registration
  | AnalyticsEvent<'CompleteRegistration'>
  // Kosik - Checkout
  // NOTE: payload is taken from this documentation: https://www.facebook.com/business/help/392174274295227?id=1205376682832142&helpref=faq_content
  | AnalyticsEvent<
      'Lead',
      {
        content_type: 'vehicle'
        content_ids: string[]
        postal_code?: string
        country?: string
        make?: string
        model?: string
        year?: number | null
        state_of_vehicle?: string
        exterior_color?: string | null
        transmission?: string | null
        body_style?: string | null
        fuel_type?: string | null
        drivetrain?: string
        price?: number
        currency: string
        preferred_price_range?: number[]
      }
    >
  // NOTE: for some reason, facebook has 'AddToWishlist' swapped with 'AddToCart' - more info here: https://driverama.atlassian.net/browse/ECOM-836
  // so AddToCart === adding car to wishlist
  // so AddToWishlist === clicking on start purchase button
  | AnalyticsEvent<
      'AddToWishlist',
      {
        content_type: 'vehicle'
        content_ids: string[]
        postal_code?: string
        country?: string
        make?: string
        model?: string
        year?: number | null
        state_of_vehicle?: string
        exterior_color?: string | null
        transmission?: string | null
        body_style?: string | null
        fuel_type?: string | null
        drivetrain?: string
        price?: number
        currency: string
        preferred_price_range?: number[]
      }
    >
  | AnalyticsEvent<'AddToCart'>

/** @deprecated */
export function logFbEvent<TName extends GetEventNames<FbEvents>>(
  name: TName,
  params?: ExtractEventParameters<TName, FbEvents>
) {
  if (!hasWindow() || !window.fbq) {
    return
  }

  window.fbq('track', name, params ? { ...params } : undefined)
}

type FbCustomEvents =
  | AnalyticsEvent<'VYKUP_VOZU'>
  | AnalyticsEvent<'VYKUP_THANKS'>
  | AnalyticsEvent<
      'PRICE_GENERATED',
      {
        priceFrom: number
        priceTo: number
      }
    >
  // Kosik - Basics
  | AnalyticsEvent<'Basics_01'>
  // Kosik - Delivery
  | AnalyticsEvent<'Delivery_02'>
  // Kosik - Future Owner
  | AnalyticsEvent<'Future_Owner_04'>
  // Kosik - Order Summary
  | AnalyticsEvent<'Order_Summary_05'>
  // Kosik - Payment Method
  | AnalyticsEvent<'Payment_Method_06'>
  | AnalyticsEvent<'phone_number'>
  | AnalyticsEvent<'test_drive', { car_id?: string }>
  | AnalyticsEvent<'book_test_drive', { car_id?: string }>

/** @deprecated */
export function logCustomFbEvent<TName extends GetEventNames<FbCustomEvents>>(
  name: TName,
  params?: ExtractEventParameters<TName, FbCustomEvents>
) {
  if (!hasWindow() || !window.fbq) {
    return
  }

  window.fbq('trackCustom', name, params ? { ...params } : undefined)
}
