export const CONTACT_HELP_EMAIL =
  process.env.NEXT_PUBLIC_REGION === 'nl'
    ? 'infonl@driverama.com'
    : 'info@driverama.com'

export const CONTACT_GENERAL_EMAIL =
  process.env.NEXT_PUBLIC_REGION === 'nl'
    ? 'infonl@driverama.com'
    : 'info@driverama.com'

export const HIRING_CONTACT_EMAIL =
  process.env.NEXT_PUBLIC_JOB_APPLICATION_EMAIL
