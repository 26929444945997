import { css } from '@emotion/react'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { color } from 'driverama-core/styles/variables'
import {
  FooterContactLinkIcon,
  FooterContactLinkIconName
} from './FooterContactLinkIcon'
import { FooterText } from './FooterText'

type Props = {
  href: string
  title: string
  iconName: FooterContactLinkIconName
  onClick?: () => void
}

export function FooterContactLink(props: Props) {
  const { href, title, iconName, onClick } = props

  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer noopener"
      onClick={onClick}
      css={css`
        width: fit-content;

        display: flex;
        justify-content: flex-start;
        align-items: center;

        cursor: pointer;
      `}
    >
      <FooterContactLinkIcon
        iconName={iconName}
        css={css({ color: color('white') })}
      />
      <Spacer size={3} axis="horizontal"></Spacer>
      <FooterText>{title}</FooterText>
    </a>
  )
}
