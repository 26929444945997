import styled from '@emotion/styled'
import { layoutGrid } from 'driverama-core/styles/common'
import { media } from 'driverama-core/styles/media'
import { size } from 'driverama-core/styles/spacing'
import { color } from 'driverama-core/styles/variables'

export const SWrapper = styled.footer`
  ${layoutGrid};
  grid-template-rows: ${size(20)} 1fr auto ${size(20)};
  background-color: ${color('night-d-200')};

  @media ${media.lte('tablet')} {
    grid-template-rows: unset;
    row-gap: ${size(12)};

    padding-top: ${size(8)};
    padding-bottom: ${size(20)};
  }
`
